import React from 'react';

export default function SignUpCompleted() {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h1>Verify Your Email</h1>
      <p>
        Click the link in the confirmation email sent to <br />
        <strong>your email address</strong> to verify your account and continue with the application.
      </p>
      <p>
        It may take a few minutes for the email to arrive. <br />
        Double-check your spam folder if you don't see it.
      </p>
      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#0070f3',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
        onClick={() => window.location.href = '/signin'} // Redirect to the login page
      >
        Go to Login
      </button>
    </div>
  );
}