import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import SignUpCompleted from "./SignUpCompleted"; // Import the component
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
export default function SignUp() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: any = new FormData(event.currentTarget);
    const email = data.get("email") as string;

    try {
      await axios.post(
        `${process.env.REACT_APP_ADB_BASE_API_URL}/onboard/user`,
        { email }
      );
      toast.success("Account created successfully! Please verify your email.");
      setSubmitted(true); // Set submitted to true to show the SignUpCompleted component
    } catch (error) {
      console.error("Signup error:", error);
      toast.error("Signup failed. Please try again.");
    }
  };

  // Conditional Rendering
  if (submitted) {
    return <SignUpCompleted />;
  }

  return (
    <>
      <Helmet>
        <title>Sign Up - AdBolder</title>
        <meta
          name="description"
          content="Sign up for your account to access all features and services."
        />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <AssignmentIndIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              size="small"
            />
            <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{ mb: 2 }}
                style={{ backgroundColor: "#1976d2" }}
              >
                Sign Up
              </Button>

              <Typography variant="body2" align="center">
                Already have an account?{" "}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/signin", {
                      replace: true,
                    });
                  }}
                  variant="body2"
                >
                  Sign In
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}