import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import { Helmet } from "react-helmet";

export default function SignIn() {
  const { login }: any = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    login({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <>
      <Helmet>
        <title>Sign In - AdBolder</title>
        <meta name="description" content="Sign in to your account to access all features and services." />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              size="small"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              size="small"
            />
            <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
              {/* Sign In Button */}
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{ mb: 2 }}
                style={{ backgroundColor: "#1976d2" }}
              >
                Sign In
              </Button>
              {/* Create Account Message */}
              <Typography variant="body2" align="center">
                Don't have an account?{' '}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/signup")}
                  variant="body2"
                >
                  Sign Up
                </Link>
              </Typography>
              {/* Forgot Password Link styled like Sign Up */}
              <Typography variant="body2" align="center">
                Forgot your password?{' '}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/initiate-forgot-password", {
                      replace: true,
                    });
                  }}
                  variant="body2"
                >
                  Reset
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}